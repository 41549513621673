<div id="app">
  <mat-sidenav-container class="sidenav-container" [dir]="direction">
    <!-- Sidebar -->
    <mat-sidenav
      #drawer
      fixedInViewport
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false"
      (mouseover)="isHover = true"
      (mouseout)="isHover = false"
      [class]="
        !(isHandset$ | async) && !isHover
          ? 'sidenav sidenav-minimize'
          : 'sidenav'
      "
    >
      <mat-toolbar color="primary">Menu</mat-toolbar>
      <mat-nav-list (click)="drawer.mode === 'over' && drawer.toggle()">
        <a mat-list-item routerLink="/home" [routerLinkActive]="'isActive'">
          <span class="link">
            <mat-icon mat-list-icon>home</mat-icon>
            <span class="text">Home</span>
          </span>
        </a>
        <a mat-list-item routerLink="/masjid/list" [routerLinkActive]="'isActive'">
          <span class="link">
            <mat-icon mat-list-icon>mosque</mat-icon>
            <span class="text">Masjids</span>
          </span>
        </a>
        <a mat-list-item routerLink="/news/list" [routerLinkActive]="'isActive'">
          <span class="link">
            <mat-icon mat-list-icon>feed</mat-icon>
            <span class="text">News</span>
          </span>
        </a>
        <a mat-list-item routerLink="/about" [routerLinkActive]="'isActive'">
          <span class="link">
            <mat-icon mat-list-icon>diversity_3</mat-icon>
            <span class="text">About</span>
          </span>
        </a>
        <a mat-list-item routerLink="/contact" [routerLinkActive]="'isActive'">
          <span class="link">
            <mat-icon mat-list-icon>call</mat-icon>
            <span class="text">Contact</span>
          </span>
        </a>
      </mat-nav-list>
      <mat-action-list class="nav-action">
        <button
          mat-fab
          *ngIf="!(isLoggedIn$ | async)"
          color="accent"
          routerLink="/auth/login"
        >
          <mat-icon>login</mat-icon>
        </button>
        <button
          mat-fab
          *ngIf="!(isLoggedIn$ | async)"
          color="accent"
          routerLink="/auth/register"
        >
          <mat-icon>create</mat-icon>
        </button>
      </mat-action-list>
    </mat-sidenav>

    <!-- Main Content -->
    <mat-sidenav-content class="main-content background-light-image">
      <app-header
        (toggleSideNavEvent)="drawer.toggle()"
        (direction)="setDirection($event)"
      ></app-header>
      <router-outlet></router-outlet>
      <app-footer></app-footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
