import { createFeatureSelector, createSelector } from '@ngrx/store'
import { AppState } from 'src/app/store/app.state'

export const selectAppState = createFeatureSelector<AppState>('appState')

export const selectAuthState = createSelector(
    selectAppState,
    (state) => state.auth
)

export const selectEditMasjidState = createSelector(
    selectAppState,
    (state) => state.editMasjid
)

export const selectMasjidState = createSelector(
    selectAppState,
    (state) => state.masjid
)

export const selectMasjidsNews = createSelector(
    selectAppState,
    (state) => state.news
)

export const selectAdminMasjids = createSelector(
    selectMasjidState, // Selector to get the masjid state
    selectAuthState,   // Selector to get the auth state
    (masjidState, authState) => {
        const currentUser = authState.currentUser;
        if (!currentUser) {
            return []; // Return an empty array if there is no logged-in user
        }
        return masjidState.masjidsList.filter((masjid) =>
            masjid.administratorsIds.includes(currentUser.userID)
        );
    }
);

export const selectCreateMasjid = createSelector(
    selectAppState,
    (state) => state.createMasjid
)