import { Component, Input, OnInit } from '@angular/core'
import { environment } from 'src/environments/environment.prod'

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: false
})
export class FooterComponent implements OnInit {
    @Input()
    appVersion: string
    ngOnInit(): void {
        this.appVersion = environment.application_version
    }
    scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
}
