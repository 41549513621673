import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { Component, OnInit } from '@angular/core'
import { Observable, throwError } from 'rxjs'
import { catchError, map, shareReplay, tap } from 'rxjs/operators'

import { Direction } from '@angular/cdk/bidi'
import { Store } from '@ngrx/store'
import { AppwriteException } from 'appwrite'
import { refreshAuthenticatedUserDataAction } from 'src/app/auth/store/actions/refresh.actions'
import { LogType } from 'src/app/shared/interfaces/log.interface'
import { AuthService } from 'src/app/shared/services/auth.service'
import { LogService } from 'src/app/shared/services/log.service'
import Utils from 'src/app/shared/services/utils'


@Component({
    selector: 'app-custom-layout',
    templateUrl: './custom-layout.component.html',
    styleUrls: ['./custom-layout.component.scss'],
    standalone: false
})
export class CustomLayoutComponent implements OnInit {
    public direction: Direction = 'ltr'
    isHover: boolean = false
    isHandset$: Observable<boolean>
    isLoggedIn$: Observable<boolean>
    isUserEmailVerified$: Observable<boolean>

    constructor(
        private breakpointObserver: BreakpointObserver,
        private _authService: AuthService,
        private store: Store,
        private _loggerService: LogService
    ) {
        this.isLoggedIn$ = this._authService.isLoggedIn
        this.isUserEmailVerified$ = this._authService.isUserEmailVerified
        this.isHandset$ = this.breakpointObserver
            .observe(Breakpoints.Handset)
            .pipe(
                map((result) => result.matches),
                shareReplay()
            )
        this._loggerService.writeLogAsync({
            message: 'Setting up the layout!',
            logType: LogType.Debug,
        })
    }

    ngOnInit(): void {
        this._authService
            .isAuthenticated()
            .pipe(
                tap((response) => {
                    this.store.dispatch(
                        refreshAuthenticatedUserDataAction({ auth: response })
                    )
                }),
                catchError((error: any) => {
                    if (error instanceof AppwriteException) {
                        const message = Utils.formatAppWriteException(error)
                        this._loggerService.writeLogAsync({
                            message: message,
                            logType: LogType.Error,
                        })
                    }
                    // Continue to propagate the error up the observable chain
                    return throwError(() => error)
                })
            )
            .subscribe()
    }

    setDirection(direction: any) {
        this.direction = direction
    }
}
