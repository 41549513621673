import { createAction, props } from '@ngrx/store'
import { MasjidDataInterface } from 'src/app/masjids/interfaces/masjidData.interface'
import { PhotoInterface } from 'src/app/shared/interfaces/Image.interface'

export const registerMasjid = createAction(
    '[Masjid] Register Masjid',
    props<{ masjidData: MasjidDataInterface }>()
)

export const registerMasjidSuccess = createAction(
    '[Masjid] Register Masjid Success',
    props<{ masjid: MasjidDataInterface }>()
)

export const registerMasjidFailure = createAction(
    '[Masjid] Register Masjid Failure',
    props<{ error: any }>()
)

export const loadMasjidList = createAction('[load] Load Masjid List')

export const loadMasjidListSuccess = createAction(
    '[load] Fill the list of masjids',
    props<{ masjids: Array<MasjidDataInterface> }>()
)
export const loadMasjidListFailure = createAction(
    '[load] Load Masjid List Failure',
    props<{ error: any }>()
)
export const viewMasjidDetailsAndNavigate = createAction(
    '[Masjid] show masjid details',
    props<{ masjidId: string }>()
)

export const loadAdminMasjids = createAction('[Masjid] Load Admin Masjids')
export const loadAdminMasjidsSuccess = createAction(
    '[Masjid] Load Admin Masjids Success',
    props<{ masjids: MasjidDataInterface[] }>()
)
export const loadAdminMasjidsFailure = createAction(
    '[Masjid] Load Admin Masjids Failure',
    props<{ error: any }>()
)
