export const environment = {
    production: true,
    application_version: '1.0.35',
    // Urls
    appwrite_url: 'https://service.masjidsinfo.com/v1',
    recover_password_url: 'https://service.masjidsinfo.com/v1/auth/recover',
    logger_url: 'https://loghub.masjidsinfo.com/api/log',
    // AppWrite
    project_id: '63bc9d260f58c37f1849',
    main_database_id: '63c5d98a009ff78b8fbe',
    // Collections
    user_address_collection_id: '63c5d9920a176e1dc5e1',
    masjid_data_collection_id: '64c83501ce963cb4030a',
    masjid_lessons_collection_id: '658236f20e8bbc83c6f0',
    masjid_contact_collection_id: '65f312c38a457726c699',
    masjid_news_collection_id: '65ffa77a455b76ab6e97',
    // Buckets
    users_avatar_bucket_id: '6434c20f857db07e2dc2',
    masjid_images_bucket: '64c8353cc4f72ee5d127',
    masjid_leader_images_bucket: '64c835502ea0bd803f6c',
    news_images_bucket: '65ffa72aa78bced27635',
    news_videos_bucket: '677219ea0dc974b88b34',
}
