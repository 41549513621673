import { Action, createReducer, on } from '@ngrx/store';
import {
    MasjidStateInterface,
    initialMasjidState,
} from 'src/app/masjids/interfaces/masjid.state.interface';
import {
    loadMasjidListSuccess,
    registerMasjidSuccess,
} from '../actions/masjid.actions';

const masjidReducer = createReducer<MasjidStateInterface>(
    initialMasjidState,
    on(registerMasjidSuccess, (state, action): MasjidStateInterface => ({
        ...state,
        masjid: action.masjid,
    })),
    on(loadMasjidListSuccess, (state, action): MasjidStateInterface => ({
        ...state,
        masjidsList: action.masjids,
    }))
);

export function reducerMasjid(
    state: MasjidStateInterface | undefined,
    action: Action
): MasjidStateInterface {
    return masjidReducer(state, action);
}
