import { Injectable, NgModule } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { Routes, TitleStrategy, RouterStateSnapshot, RouterModule } from '@angular/router'
import { AboutComponent } from 'src/app/components/about/about.component'
import { ContactComponent } from 'src/app/components/contact/contact.component'
import { HomeComponent } from 'src/app/components/home/home.component'
import { GlobalErrorComponent } from 'src/app/shared/components/global-error/global-error.component'
import { CustomLayoutComponent } from 'src/app/shared/components/layout/custom-layout/custom-layout.component'
import { PageNotFoundComponent } from 'src/app/shared/components/page-not-found/page-not-found.component'

const routes: Routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    {
        path: '',
        component: CustomLayoutComponent,
        children: [
            { path: 'home', title: 'Home', component: HomeComponent },
            { path: 'about', title: 'About', component: AboutComponent },
            {
                path: 'news',
                loadChildren: () =>
                    import('src/app/news/news.module').then(
                        (m) => m.NewsModule
                    ),
            },
            { path: 'contact', title: 'Contact', component: ContactComponent },

            {
                path: 'auth',
                loadChildren: () =>
                    import('src/app/auth/auth.module').then(
                        (m) => m.AuthModule
                    ),
            },
            {
                path: 'masjid',
                loadChildren: () =>
                    import('src/app/masjids/masjid.module').then(
                        (m) => m.MasjidModule
                    ),
            },
            {
                path: 'error',
                title: 'Error was found',
                component: GlobalErrorComponent,
            },
            {
                path: '**',
                title: '404 Page Not Found',
                component: PageNotFoundComponent,
            },
        ],
    },
]

@Injectable({ providedIn: 'root' })
export class TemplatePageTitleStrategy extends TitleStrategy {
    constructor(private readonly title: Title) {
        super()
    }

    override updateTitle(routerState: RouterStateSnapshot): void {
        const title = this.buildTitle(routerState)
        if (title === '404 Page Not Found') {
            return this.title.setTitle(title)
        }

        if (title !== undefined) {
            return this.title.setTitle(`Masjids Info - ${title}`)
        }

        this.title.setTitle('Masjids Info')
    }
}

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [
        { provide: TitleStrategy, useClass: TemplatePageTitleStrategy },
    ],
})
export class AppRoutingModule {}
