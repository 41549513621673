import { AppRoutingModule } from 'src/app/app-routing.module'

//material ui
import { LayoutModule } from '@angular/cdk/layout'
import { MatButtonModule } from '@angular/material/button'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatIconModule } from '@angular/material/icon'
import { MatListModule } from '@angular/material/list'
import { MatMenuModule } from '@angular/material/menu'
import { MatSelectModule } from '@angular/material/select'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatToolbarModule } from '@angular/material/toolbar'

//components
import { MatCardModule } from '@angular/material/card'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatRadioModule } from '@angular/material/radio'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { AppComponent } from 'src/app/app.component'
import { AboutComponent } from 'src/app/components/about/about.component'
import { TeamMemberComponent } from 'src/app/components/about/team-member/team-member.component'
import { ContactComponent } from 'src/app/components/contact/contact.component'
import { FooterComponent } from 'src/app/shared/components/footer/footer.component'
import { GlobalErrorComponent } from 'src/app/shared/components/global-error/global-error.component'
import { HeaderComponent } from 'src/app/shared/components/header/header.component'
import { CustomLayoutComponent } from 'src/app/shared/components/layout/custom-layout/custom-layout.component'
import { PageNotFoundComponent } from 'src/app/shared/components/page-not-found/page-not-found.component'
import { rootReducer } from 'src/app/store/app.reducer'
import { TranslocoRootModule } from 'src/app/transloco-root.module'
import { isDevMode, NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { provideHttpClient } from '@angular/common/http'
import { initialAppState } from './store/app.state'
const enableDevtools = isDevMode()

@NgModule({
    providers: [provideHttpClient()],
    declarations: [
        CustomLayoutComponent,
        AppComponent,
        FooterComponent,
        HeaderComponent,
        ContactComponent,
        AboutComponent,
        PageNotFoundComponent,
        TeamMemberComponent,
        GlobalErrorComponent,
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        MatToolbarModule,
        MatMenuModule,
        MatIconModule,
        MatButtonModule,
        MatSelectModule,
        MatSidenavModule,
        LayoutModule,
        MatInputModule,
        MatRadioModule,
        MatCardModule,
        MatGridListModule,
        ReactiveFormsModule,
        MatSlideToggleModule,
        MatFormFieldModule,
        MatProgressBarModule,
        MatListModule,
        StoreModule.forRoot(rootReducer, {
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
                strictActionSerializability: true,
                strictStateSerializability: true,
            },
        }),
        EffectsModule.forRoot([]),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: !enableDevtools,
            autoPause: true,
            trace: true,
        }),
        TranslocoRootModule,
    ],
})
export class AppModule {}
