import { ActionReducerMap, combineReducers } from '@ngrx/store'
import { reducerAuth } from '../auth/store/reducers'
import { reducerEditMasjid } from '../masjids/store/reducers/edit-masjid.reducer'
import { reducerMasjid } from '../masjids/store/reducers/masjid.reducer'
import { reducerNews } from '../news/store/reducers'
import { reducerCreateMasjid } from '../masjids/store/reducers/create.masjid.reducer'
import { AppState } from './app.state'

// export const rootReducer = combineReducers({
//     auth: reducerAuth,
//     editMasjid: reducerEditMasjid,
//     masjid: reducerMasjid,
//     createMasjid: reducerCreateMasjid,
//     news: reducerNews,
// })

export const rootReducer: ActionReducerMap<AppState> = {
    auth: reducerAuth,
    masjid: reducerMasjid,
    editMasjid: reducerEditMasjid,
    createMasjid: reducerCreateMasjid,
    news: reducerNews
};
